import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoCard from "../../components/cards/info-card";
import tools from "../../json/tools.json";

import baseStyles from "../../css/const";
import Paper from "@material-ui/core/Paper";
import { Box, Button, Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { renderVideo } from "../../components/cards/video";
import { ExampleCode } from "./tool";

export default function GettingStarted() {
  // set document title
  const [title, setTitle] = useDocTitle("Getting Started");

  // setup tracker after setting the document title
  useTracker();

  const classes = baseStyles();


  const render_topic_box = (props) => {
    const tool_page = props.tool_page;
    const title = props.title;
    const text = props.text;
    const code_example = props.code_example;
    const tutorials = props.tutorials;
    const videos = props.videos;

    return (
        <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
          <Grid container justify="left" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>{title}</Typography>
            </Grid>

            <Grid item xs={12} ms={12} md={12}>
              <Typography align="left">{text}</Typography>
            </Grid>

            <Grid item xs={"auto"}>
              <Button component={Link} variant="contained" className={classes.button} to={tool_page}>
                More Information
              </Button>
            </Grid>

            {code_example && (
              <Grid item xs={12}>
                <ExampleCode source={code_example} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container direction="row" justify="left" spacing={3}>
                {videos &&
                videos.map(function (item) {
                  return (
                    <Grid item xs={12} sm={12} md={6}>
                      <Card className={classes.paper} variant={"outlined"} style={{ width: "100%" }}>
                        {renderVideo(item)}
                      </Card>
                    </Grid>
                  );
                })}

                {tutorials.map(function (item) {
                  const paperProps = {
                    title: item.title,
                    link: item.link,
                    description: item.description,
                    bg_image: item.bg_image,
                  };

                  return (
                    <Grid item xs={12} sm={6} md={6}>
                      <InfoCard {...paperProps} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
    );
  };

  return (
    <body className={classes.body}>
      <DefaultLayout>
        <main>
          <Grid container direction="column" justify="center" spacing={2}>
            {/*<Grid>*/}
            <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
              <Grid item xs={12}>
                <Typography className={classes.title}>Getting Started: Tutorials</Typography>
              </Grid>

              <Grid item ms={12} md={12}>
                <Typography align="left">
                  Along with our benchmark, we provide a range of tools each focusing on a different aspect of motion
                  planning for autonomous vessels. To ease getting started with using the tools, we provide minimal
                  examples and entry-level tutorials. For more advanced tutorials and further information about the
                  tool, please have a look at the corresponding tool pages.
                </Typography>
              </Grid>
            </Paper>

            {/* Commonocean-sim*/}
            {render_topic_box({
              tool_page: "/commonocean-sim",
              title: "CommonOcean Simulator",
              text: (
                <Typography>
                  CommonOcean-Sim is a simulation environment for maritime traffic. Designed specifically for 
                  autonomous vessel motion planning, CommonOcean-Sim enables configurable simulation using 
                  CommonOcean traffic scenarios. Simulation scenarios can be configured with different controllers 
                  and vessel types. CommonOcean-Sim features a modular architecture that allows for seamless integration 
                  of control algorithms as well as extensive configurability and scalability to a multitude of traffic 
                  situations, including multi-vessel, interactive, and high-density traffic.
                </Typography>
              ),
              code_example: tools.sim.general.code_example,
              tutorials: tools.sim.tutorials.getting_started,
            })}


            {/* Commonocean-io*/}
            {render_topic_box({
              tool_page: "/commonocean-io",
              title: "CommonOcean Input-Output",
              text: (
                <Typography>
                  A comprehensive introduction to CommonOcean benchmarks will be published soon in form of a scientific
                  paper. In short, a benchmark consists of a scenario identifier, a cost function, and a vessel model
                  and vessel type. The CommonOcean Input-Output package provides methods to read, write, and visualize
                  CommonOcean scenarios and planning problems. Furthermore, it can be used as a framework for implementing
                  motion planning algorithms to solve CommonOcean Benchmarks and is the basis for other tools of the
                  CommonOcean Framework.
                </Typography>
              ),
              code_example: tools.io.general.code_example,
              tutorials: tools.io.tutorials.getting_started,
            })}

            {/* Commonocean DC*/}
            {render_topic_box({
              tool_page: "/commonocean-dc",
              title: "CommonOcean Drivability Checker",
              text: (
                <Typography>
                Collision avoidance, kinematic feasibility, and water-compliance must be 
                validated to ensure the drivability of planned motions for autonomous 
                vessels. The CommonOcean Drivability Checker toolbox unifies these checks 
                in order to simplify the development and validation of motion planning 
                algorithms. It is compatible with the CommonOcean benchmark suite, which 
                additionally facilitates and drastically reduces the effort of the development
                of motion planning algorithms.
                </Typography>
              ),
              code_example: tools.dc.general.code_example,
              tutorials: tools.dc.tutorials.getting_started,
            })}

            {/* Commonocean Rules*/}
            {render_topic_box({
              tool_page: "/commonocean-rules",
              title: "CommonOcean Rules",
              text: (
                <Typography>
                  CommonOcean Rules provides a python traffic rule monitor for ships on the open sea. In detail, the rules
                  for power-driven vessels at the open sea are formalized with metric temporal logic, and rule compliance of
                  vessels can be evaluated for CommonOcean scenarios with this tool. For more details please check our <a href={"https://mediatum.ub.tum.de/node?id=1613345"}>paper</a>.
                </Typography>
              ),
              code_example: tools.rules.general.code_example,
              tutorials: tools.rules.tutorials.getting_started,
            })}

          </Grid>

          {/*</Grid>*/}
          
          <Grid container direction="row" alignItems="center" justifyContent="center" justify="center" spacing={2}>
            {/*<Grid>*/}
            <Paper className={classes.paper} variant={"outlined"} style={{ marginBottom: "50px", width: "100%" }}>
              <Grid item xs={12}>
                <Typography className={classes.subtitle}>CommonOcean dependencies</Typography>
              </Grid>

              The following figure indicates the CommonOcean modules' dependencies on each other. Starting the overview, the Input-Ouput
              tool shows itself as a central part of the complete framework, being only optionally dependent of the Vessel Models module.
              The Drivability Checker, the Rules, the Data Converters and the Scenarios tools are all connected to the IO manner of dealing with
              motion planning. In addition, the Drivability Checker also depends directly from the Rules and from the Vessel Models, necessarily being installed
              after these modules. 
             
              <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center">

              <Grid item xs={12} md={6} style={{ marginTop: "15px" }}>
                
                <img
                  style={{ width: "100%", maxWidth: "100%",  marginTop: "2vh" }}
                  alt="dependencies"
                  src={require("../../assets/CO_Dependencies_Graph_public_version.svg").default}
                />
              </Grid>
              </Box>
            </Paper>

          </Grid>
        </main>
      </DefaultLayout>
    </body>
  );
}
